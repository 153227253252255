<template>
  <div class="home">
    <div class="back">
      <img class="top_left" src="../assets/top_left.png" alt="" />
      <img class="bottom_left" src="../assets/bottom_left.png" alt="" />
      <img class="bottom_right" src="../assets/bottom_right.png" alt="" />
      <div class="message">
        <img class="gift" src="../assets/gift.png" alt="" />
        <img class="top_right" src="../assets/top_right.png" alt="" />
        <h2>Get Your Gift Card!</h2>
        <div class="input">
          <el-input
            v-model="input"
            placeholder="Enter your verification code here."
          ></el-input>
          <el-button type="primary" class="button" @click="submit"
            >Confirm</el-button
          >
        </div>
        <p class="title">Your gift card code：</p>
        <div class="input">
          <el-input v-model="value" :disabled="true" id="card"></el-input>
          <el-button type="primary" class="button" @click="copy"
            >Copy</el-button
          >
        </div>
        <a v-show="show" class="question" :href="address" target="_blank"
          >How to redeem the gift card?</a
        >
        <p class="answer">
          Please feel free to
          <a href="mailto:service@septekon.com" target="_blank">contact us</a>
          if you have any problems.<br />
          Email:
          <a href="mailto:service@septekon.com" target="_blank"
            >service@septekon.com</a
          >
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home',
  data () {
    return {
      input: '',
      value: '',
      show: false,
      address: ''
    }
  },
  created () {
    let value = this.urlSearch('code')
    if (value) {
      this.input = value
    }
  },
  methods: {
    urlSearch (argName) {
      if (!argName) {
        return false;
      }
      var args = {},
        query = location.search.substring(1),
        pairs = query.split("&");
      for (var i = 0; i < pairs.length; i++) {
        var pos = pairs[i].indexOf('=');
        if (pos == -1) continue;
        var argname = pairs[i].substring(0, pos),
          value = pairs[i].substring(pos + 1);
        value = decodeURIComponent(value);
        if (argName == argname) {
          return value;
        }
      }
    },
    async getActiveCodeList () {
      const { data: res } = await this.$http.post('/mgr/card-active', {
        active_code: this.input
      })
      if (res.code == '4001') {
        this.$alert('Invalid active code.', 'Tips:', {
          confirmButtonText: '确定',
          type: 'error'
        })
      } else if (res.code == '4002') {
        this.$alert('Invalid active code.', 'Tips:', {
          confirmButtonText: '确定',
          type: 'error'
        })
      } else if (res.code == '4003') {
        this.$alert('The gift card is out of stock, please <a href="mailto:service@septekon.com" target="_blank">contact us</a> to get it.', 'Tips:', {
          confirmButtonText: '确定',
          type: 'warning',
          dangerouslyUseHTMLString: true
        })
      } else {
        let unit = ''
        if (res.result.unit == '1') {
          unit = '£'
          this.address = 'https://www.amazon.co.uk/gp/help/customer/display.html?nodeId=201936940'
        } else if (res.result.unit == '2') {
          unit = '$'
          this.address = 'https://www.amazon.com/gp/help/customer/display.html?nodeId=201936940'
        } else if (res.result.unit == '4') {
          unit = 'C$'
          this.address = 'https://www.amazon.ca/gp/help/customer/display.html?nodeId=G5D4TA7NBKQT7GW2'
        } else if (res.result.unit == '3') {
          unit = '¥'
        }
        this.value = '(' + unit + res.result.amount / 100 + ')' + res.result.card_code
        this.show = true
      }
      this.input = ''
    },
    submit () {
      if (this.input) {
        this.getActiveCodeList()
      } else {
        this.$message({
          message: 'Please enter your verification code!',
          type: 'error'
        })
      }
    },
    copy () {
      let num = this.value.indexOf(")")
      // vue直接获取不到dom节点，通过创建临时节点选中value值，复制后删除节点
      let input = document.createElement("input")
      input.value = this.value.substr(num + 1)
      document.body.appendChild(input)
      input.select()
      document.execCommand("Copy")
      document.body.removeChild(input)
      this.$message({
        message: 'Copy successfully!',
        type: 'success'
      })
    }
  }
}
</script>
<style>
@media screen and (min-width: 1024px) {
  .home {
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to right, #3c5fff, #2993ff);
  }

  .back {
    width: 100%;
    height: 100%;
    position: relative;
  }

  .top_left {
    width: 247px;
    height: 130px;
    position: absolute;
    left: 4%;
    top: 0;
  }

  .top_right {
    width: 507px;
    height: 328px;
    position: absolute;
    right: -14%;
    top: -32%;
    z-index: -1;
  }

  .bottom_left {
    width: 134px;
    height: 174px;
    position: absolute;
    left: 12%;
    bottom: 0;
  }

  .bottom_right {
    width: 304px;
    height: 285px;
    position: absolute;
    right: 0;
    bottom: 0;
  }

  .message {
    width: 880px;
    height: 528px;
    background-color: #fff;
    position: absolute;
    top: 53%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
    box-shadow: #666 0 0 10px;
  }

  .gift {
    width: 253px;
    height: 245px;
    position: absolute;
    left: 0%;
    top: -22%;
  }

  h2 {
    text-align: center;
    margin-top: 70px;
    margin-bottom: 52px;
    font-size: 36px;
    line-height: 54px;
  }

  .input {
    margin: 0 auto;
    width: 600px;
    height: 50px;
  }

  .el-input {
    width: 460px;
    margin-right: 30px;
  }

  .el-input__inner,
  .el-input__inner:hover {
    height: 50px;
    border: 1px solid #3d5eff;
  }

  .el-input.is-disabled .el-input__inner {
    cursor: default;
    border: 1px solid #3d5eff;
    color: black;
  }

  .button {
    width: 110px;
    height: 50px;
    font-size: 20px;
    text-align: center;
    background-color: #3d5eff;
    border-color: #3d5eff;
  }

  .title {
    margin: 45px 0 10px 140px;
    font-size: 22px;
    line-height: 28px;
    font-weight: 600;
    color: #333333;
  }

  .question {
    display: block;
    margin-top: 20px;
    margin-left: 140px;
    font-size: 16px;
    line-height: 20px;
    color: #3d5eff;
  }

  .answer {
    margin-top: 10px;
    margin-left: 140px;
    font-size: 16px;
    line-height: 28px;
    width: 460px;
    color: #999;
  }

  a {
    text-decoration: none;
    color: #3d5eff;
  }
}
@media screen and (max-width: 1023px) {
  .home {
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to right, #3c5fff, #2993ff);
    overflow: hidden;
  }
  .back {
    width: 100%;
    height: 100%;
    position: relative;
  }
  .top_left {
    width: 247px;
    height: 130px;
    position: absolute;
    left: 4%;
    top: 0;
  }

  .top_right {
    width: 507px;
    height: 328px;
    position: absolute;
    right: -14%;
    top: -32%;
    z-index: -1;
  }

  .bottom_left {
    width: 134px;
    height: 174px;
    position: absolute;
    left: 12%;
    bottom: 0;
  }

  .bottom_right {
    width: 304px;
    height: 285px;
    position: absolute;
    right: 0;
    bottom: 0;
  }
  .message {
    width: 1600px;
    height: 1200px;
    background-color: #fff;
    position: absolute;
    top: 53%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
    box-shadow: #666 0 0 10px;
  }
  .gift {
    width: 500px;
    height: 480px;
    position: absolute;
    left: 0%;
    top: -20%;
  }
  h2 {
    text-align: center;
    margin-top: 140px;
    margin-bottom: 100px;
    font-size: 72px;
    line-height: 100px;
  }

  .input {
    margin: 0 auto;
    width: 1300px;
    height: 100px;
  }

  .el-input {
    width: 970px;
    margin-right: 60px;
  }

  .el-input__inner,
  .el-input__inner:hover {
    height: 100px;
    border: 1px solid #3d5eff;
    font-size: 48px;
    line-height: 64px;
  }

  .el-input.is-disabled .el-input__inner {
    cursor: default;
    border: 1px solid #3d5eff;
    color: black;
  }

  .button {
    width: 270px;
    height: 100px;
    font-size: 54px;
    text-align: center;
    background-color: #3d5eff;
    border-color: #3d5eff;
  }

  .title {
    margin: 60px 0 15px 150px;
    font-size: 60px;
    line-height: 80px;
    font-weight: 600;
    color: #333333;
  }

  .question {
    display: block;
    margin-top: 56px;
    margin-left: 150px;
    font-size: 54px;
    line-height: 72px;
    color: #3d5eff;
  }

  .answer {
    margin-top: 30px;
    margin-left: 150px;
    font-size: 54px;
    line-height: 90px;
    width: 970px;
    color: #999;
  }

  a {
    text-decoration: none;
    color: #3d5eff;
  }
}
</style>

